'use strict';

/**
 * resizeSlider
 * @param {string} $slider - slider element
 * @param {Object} cssObj - css Object
 */
function resizeSlider($slider, cssObj) {
    $slider.css(cssObj);
}

module.exports = function () {
    if ($('.testimonial-slider.slick-initialized').length) {
        $('.testimonial-slider').slick('unslick');
    }

    $('.testimonial-slider').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: true,
        arrows: false,
        dots: true,
        customPaging: function () {
            setTimeout(() => { $('.testimonial-slider .slick-slide').removeAttr('role'); }, 10);
        },
        responsive: [{
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                variableWidth: true,
                arrows: false,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                variableWidth: false,
                arrows: false,
                dots: true
            }
        }]
    });

    $('.hair-type-tab').on('click', function (e) {
        e.preventDefault();
        var id = $(this).data('target');
        var sliders = $('.hair-type-sliders');

        $('.hair-type-nav').find('.active').removeClass('active');
        $(this).addClass('active');

        sliders.find('.active').removeClass('active').hide();
        sliders.find('#' + id).show().addClass('active');
    });

    $('.hair-type-tab').on('keydown', function (e) {
        if (e.which === 39 || e.which === 37) {
            e.preventDefault();
            var currentIndex = $('.hair-type-tab').index(this);
            var nextIndex = (e.which === 39) ? (currentIndex + 1) % $('.hair-type-tab').length : (currentIndex - 1 + $('.hair-type-tab').length) % $('.hair-type-tab').length;
            
            $('.hair-type-tab').eq(nextIndex).trigger('click').focus();
        }
    });

    $('.hair-type-slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        variableWidth: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        mobileFirst: true,
        customPaging: function () {
            setTimeout(() => { $('.hair-type-slider .slick-slide').removeAttr('role'); }, 10);
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.slider-alt').slick({
        dots: true,
        arrows: false,
        infinite: true,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        customPaging: function () {
            setTimeout(() => { $('.slider-alt .slick-slide').removeAttr('role'); }, 10);
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    dots: false
                }
            }
        ]
    });

    $('.r-d-slider').slick({
        dots: true,
        arrows: false,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        mobileFirst: true,
        customPaging: function () {
            setTimeout(() => { $('.r-d-slider .slick-slide').removeAttr('role'); }, 10);
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    $('.video-component--modal, .img-video-component--modal').on('hidden.bs.modal', function () {
        var $iframe = $(this).find('iframe');
        var $video = $(this).find('video');

        if ($iframe.length) {
            $iframe.attr('src', $iframe.attr('src'));
        } else if ($video.length) {
            $video.get(0).pause();
            $video.get(0).currentTime = 0;
        }
    });

    $('.video-component .video-component--image').on('keydown', function(event) {
        if (event.which === 13) {
            event.preventDefault();
            $(this).trigger('click');
        }
    });

    // Category Bottom Text Slider
    $('.testimonials-component--text_slider:not(.js-testimonials-text-slider)')
        .slick({
            dots: true,
            arrows: false,
            mobileFirst: true,
            customPaging: function () {
                setTimeout(() => { $('.hair-type-slider .slick-slide').removeAttr('role'); }, 10);
            },
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        dots: false,
                        arrows: true,
                        prevArrow: $('.slick-prev--custom'),
                        nextArrow: $('.slick-next--custom')
                    }
                }
            ]
        }).on('setPosition', function () {
            if (window.matchMedia('(min-width: 48em)').matches) {
                var $thisSlider = $(this);
                var diff = $thisSlider.closest('.col-12.col-md-4').outerHeight() - $thisSlider.closest('.testimonials-component--text').outerHeight();

                if (diff > 0) {
                    resizeSlider($thisSlider.closest('.testimonials-component--text').find('.testimonials-component--text_footer'), {
                        'margin-top': diff + 'px'
                    });
                }
            }
        });

    $('.js-testimonials-video-slider').each(function () {
        var videoSlider = $(this);
        var textSlider = videoSlider.parent().siblings().find('.js-testimonials-text-slider');
        var textSliderItem = textSlider.find('.testimonials-component--text_item');

        if (textSliderItem.length > 1) {
            videoSlider.slick({
                dots: false,
                arrows: false,
                asNavFor: textSlider,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true
            });
            textSlider.slick({
                dots: false,
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                asNavFor: videoSlider,
                prevArrow: textSlider.siblings().find('.slick-prev--custom'),
                nextArrow: textSlider.siblings().find('.slick-next--custom'),
                customPaging: function () {
                    setTimeout(() => { $('.hair-type-slider .slick-slide').removeAttr('role'); }, 10);
                },
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            dots: false,
                            arrows: false
                        }
                    }
                ]
            }).on('setPosition', function () {
                if (window.matchMedia('(min-width: 48em)').matches) {
                    var $thisSlider = $(this);
                    var diff = $thisSlider.closest('.col-12.col-md-4').outerHeight() - $thisSlider.closest('.testimonials-component--text').outerHeight();
                    if (diff > 0) {
                        resizeSlider($thisSlider.closest('.testimonials-component--text').find('.testimonials-component--text_footer'), {
                            'margin-top': diff + 'px'
                        });
                    }
                }
            });
        } else {
            videoSlider.slick({
                dots: false,
                arrows: true,
                asNavFor: textSlider,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                prevArrow: videoSlider.parent().siblings().find('.slick-prev--custom'),
                nextArrow: videoSlider.parent().siblings().find('.slick-next--custom')
            });
            textSlider.slick({
                dots: false,
                arrows: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                asNavFor: videoSlider,
                customPaging: function () {
                    setTimeout(() => { $('.hair-type-slider .slick-slide').removeAttr('role'); }, 10);
                },
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            dots: false,
                            arrows: false
                        }
                    }
                ]
            }).on('setPosition', function () {
                if (window.matchMedia('(min-width: 48em)').matches) {
                    var $thisSlider = $(this);
                    var diff = $thisSlider.closest('.col-12.col-md-4').outerHeight() - $thisSlider.closest('.testimonials-component--text').outerHeight();
                    if (diff > 0) {
                        resizeSlider($thisSlider.closest('.testimonials-component--text').find('.testimonials-component--text_footer'), {
                            'margin-top': diff + 'px'
                        });
                    }
                }
            });
        }
    });

    $(window).smartresize(function () {
        if (window.matchMedia('(min-width: 48em)').matches) {
            var $thisSlider = $('.testimonials-component--text_slider.slick-initialized');
            var diff = $thisSlider.closest('.col-12.col-md-4').outerHeight() - $thisSlider.closest('.testimonials-component--text').outerHeight();

            if (diff > 0) {
                resizeSlider($thisSlider.closest('.testimonials-component--text').find('.testimonials-component--text_footer'), {
                    'margin-top': diff + 'px'
                });
            }
        }
    });

    $('#testimonialsComponentVideoModal').on('hidden.bs.modal', function () {
        var $iframe = $(this).find('iframe');
        var $video = $(this).find('video');

        if ($iframe.length) {
            $iframe.attr('src', $iframe.attr('src'));
        } else if ($video.length) {
            $video.get(0).pause();
            $video.get(0).currentTime = 0;
        }
    });
};
