'use strict';

var $scrollTop = $(window).scrollTop(),
    $body      = $('body'),
    $header    = $('.page > header'),
    $hSlot     = $header.find('.header-slot');

/**
 * Set Scroll FX
 * @param {number} scrollPos - current scroll position
 * @param {number} offset - current offset value
 * @param {string} breakpoint - current breakpoint
 * breakpoint: current breakpoint value: string
 */
function setScrollFx(scrollPos, offset, breakpoint) {
    var scrollDir = scrollPos > $scrollTop ? 'down' : 'up';
    var hOffset = parseInt($header.css('top'));
    // Toggle utility class on scroll
    if (scrollPos > 1) {
        $body.addClass('is-scrolled');
    } else {
        $body.removeClass('is-scrolled');
    }
    // Set scroll FX when applicable - condense header on scroll down only, toggle utility class

    if (scrollDir === 'down') {
        // Scroll down FX
        //$header.css('top', offset).addClass('offset');
    } else if (scrollDir === 'up') {
        // Scroll up FX
        $header.css('top', 0).removeClass('offset');
    }

    // Check for header offset, set body padding, suggestions position
    if ($header.hasClass('offset')) {
        $body.css('padding-top', ($header.height() + hOffset) + 10);
        //$('.suggestions-inner-wrapper').height($(window).height() - $header.height()).parent().css('top', ($header.height() + hOffset ));
        //$('.refinement-bar').height($(window).height() - $header.height()).css('top', ($header.height() + hOffset ));
    } else {
        if ($body.hasClass('is-sticky')) {
            $body.css('padding-top', $header.height() + 41);

            if (breakpoint === 'mobile') {
                $('.suggestions-inner-wrapper').height($(window).height() - 130);
            }
        }
        //$('.refinement-bar').height($(window).height() - $header.height()).css('top', $header.height());
    }

        // Reset offset on Desktop
        //$header.attr('style', '');
        //$body.attr('style', '');
        //$('.refinement-bar').attr('style', '');
    // Reset scroll position, prevent negative scroll
    $scrollTop = scrollPos <= 0 ? 0 : scrollPos;
}

/**
 * Set Header
 */
function setHeader() {
    var $scrollPos = $(window).scrollTop();
    var $offsetH = -($hSlot.outerHeight());
    var $bp = window.matchMedia('(max-width: 48em)').matches ? 'mobile' : 'desktop';
    // Set Header FX
    setScrollFx($scrollPos, $offsetH, $bp);
}

module.exports = function () {
    setHeader();

    $body.on('click', '.modal-link', function () {
        var $modal = $($(this).attr('data-target'));
        var showAccent = $(this).data('accent');

        // Clear previous accent color
        $modal.find('.showAccent').removeClass('showAccent');

        // Check attribute for modal accent color, show accordingly
        if (showAccent) {
            $($(this).attr('data-target')).find('.modal-body').addClass('showAccent');
        }
    });

    $body.on('change', '#header-theme', function () {

        $body.removeClass('full-bleed theme-dark theme-light');

        if ($(this).val() === 'default') {
            $body.addClass($(this).val());
        } else {
            $body.addClass('full-bleed ' + $(this).val());
        }
    });

    $body.on('click', '.navbar-toggler', function () {
        $body.toggleClass('menu-active');
    });

    $body.on('click', '.navbar > .close-menu .close-button button', function () {
        $body.removeClass('menu-active');
    });

    $body.on('click', '.close-menu .close-button button', function () {
        $('.modal-background').hide();
    });

    $body.on('click', '.close-minicart', function () {
        $body.removeClass('minicart-active');
        $header.find('.minicart .popover').removeClass('show');
    });

    $body.on('click', '.flyout-background', function () {
        $body.removeClass('flyout-active menu-active search-active minicart-active filter-active drawer-active ingredients-active');
        $body.find('.mobile-attributes-wrap.active').removeClass('active');
        $header.find('.minicart .popover').removeClass('show');
    });

    $body.on('click', '.close-flyout', function () {
        var flyoutTarget = $(this).data('target');
        $body.removeClass('flyout-active ' + flyoutTarget + '-active');
        if (flyoutTarget === 'sort') {
            $body.removeClass('filter-active');
            $('.refinement-bar, .modal-background').hide();
        }
    });

    $body.on('click', '.close-header-slot', function () {
        $('.header-top').remove();
        setHeader();
    });

    $body.on('click', '.search-toggle', function () {
        $body.toggleClass('flyout-active search-active');
        if ($body.hasClass('search-active')) {
            $header.find('.search-field').focus();
        } else {
            $header.find('.search-field').blur();
        }
    });

    $body.on('click', '.filter-results, .refinement-bar .close', function () {
        setHeader();
        $body.removeClass('flyout-active filter-active');

        $('.refinement-bar').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.row').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.tab-pane.active').siblings().attr('aria-hidden', false);
        $('.refinement-bar').closest('.container.search-results').siblings().attr('aria-hidden', false);
        $('.btn.filter-results').focus();
    });

    $(window).smartresize(function () {
        var bp = window.matchMedia('(max-width: 48em)').matches ? 'mobile' : 'desktop';
        setHeader();

        if (bp === 'desktop') {
            $body.removeClass('menu-active');
        }
    });

    $(window).on('scroll', function () {
        setHeader();
    });

    // Open Dropdown Menu with Keyboard
    $('.navbar-nav > .dropdown').on('keydown', function (event) {
        var $dropdown = $(this);
        var $dropdownToggle = $dropdown.find('.dropdown-toggle');
        var $dropdownMenu = $dropdown.find('.dropdown-menu');
        var $dropdownItems = $dropdownMenu.find('.dropdown-item');

        // Enter or Spacebar pressed
        if (event.which === 13 || event.which === 32) {
            event.preventDefault();
            var ariaExpanded = $dropdownToggle.attr('aria-expanded') === 'true';
            if (ariaExpanded) {
                var $focusedItem = $dropdownItems.filter(':focus');
                if ($focusedItem.length) {
                    $focusedItem.find('.dropdown-link')[0].click();
                    $dropdown.addClass('show');
                }
            } else {
                $dropdownToggle.attr('aria-expanded', !ariaExpanded);
                $dropdown.toggleClass('show');

                if (!ariaExpanded) {
                    $dropdownToggle.focus();
                }
            }
        } else if (event.which === 27) { // Escape key pressed
            $dropdown.removeClass('show');
            $(this).focus();

        } else if (event.which === 40) { // Down arrow key pressed
            if (!$dropdown.hasClass('show')) {
                $dropdown.addClass('show');
                $dropdownToggle.attr('aria-expanded', true);
                $dropdownItems.first().focus();
            } else {
                var currentIndex = $dropdownItems.index($dropdownMenu.find(':focus'));

                if (currentIndex < $dropdownItems.length - 1) {
                    $dropdownItems.eq(currentIndex + 1).focus();
                }
            }
        } else if (event.which === 37 || event.which === 39) { // Left or right arrow key pressed
            $dropdown.removeClass('show');
            var $nextLi = event.which === 39 ? $dropdown.next('li') : $dropdown.prev('li');

            if ($nextLi.length > 0) {
                $nextLi.find('.nav-link').focus();
            }
        }
    });

    $('.navbar-nav .dropdown-menu .dropdown-item').on('keydown', function (event) {
        var $item = $(this);
        var $dropdown = $item.closest('.dropdown');
        var $dropdownItems = $dropdown.find('.dropdown-menu .dropdown-item');

        // Escape key pressed
        if (event.which === 27) {
            $dropdown.removeClass('show');
            $dropdown.focus();
        } else if (event.which === 38) {   // Up arrow key pressed
            var currentIndex = $dropdownItems.index($item);
            if (currentIndex > 0) {
                $dropdownItems.eq(currentIndex - 1).focus();
            }
        }
    });
};
