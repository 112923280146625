'use strict';

/**
 * initOGSetup function
 */
function initOGSetup() {
    setInterval(function () {
        const nonSubscriptionElements = [$('.braintree-cart-apple-buttons-wrap'), $('.braintree-cart-paypal-buttons-wrap'), $('.terms-non-subscription'), $('.cart-afterpay-container')];
        const subscriptionElement = $('.terms-subscription');
        if (window.OG && window.OG.getOptins().length > 0 && window.autoShip === 'true') {
            nonSubscriptionElements.forEach((item) => {
                item.hide();
            });
            subscriptionElement.show();
        } else {
            nonSubscriptionElements.forEach((item) => {
                item.show();
            });
            subscriptionElement.hide();
        }
    }, 2000);
}
/**
 * A function to call the OrderGroove-PurchasePostTracking endpoint with the current OG optins.
 */
function onOptinChanged() {
    $.ajax({
        url: window.OrdergrooveTrackingUrl,
        method: 'POST',
        data: { tracking: JSON.stringify(window.OG.getOptins()) },
        success: function () {
            // We want to trigger a quantity change event even though the quantity isn't changing
            // so that we execute the same logic that refreshes discounts when the quantity changes
            $('.quantity-form > .quantity').trigger('change');
            initOGSetup();
        }
    });
}

module.exports = {
    optinChange: function () {
        if (!window.OrdergrooveLegacyOffers && window.OG) {
            window.OG.addOptinChangedCallback(onOptinChanged);
        }
    },
    initOGSetup: initOGSetup
};
