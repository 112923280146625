'use strict';

require('core/thirdParty/slick');

module.exports = function () {
    $('.slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: function () {
            setTimeout(() => { $('.slider .slick-slide').removeAttr('role'); }, 10);
        },
    });

    $('.product-slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 4,
        variableWidth: false,
        slidesToScroll: 1,
        customPaging: function () {
            setTimeout(() => { $('.product-slider .slick-slide').removeAttr('role'); }, 10);
        },
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    });

    $('.js-slider-bonusPproducts').slick({
        dots: true,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        customPaging: function () {
            setTimeout(() => { $('.js-slider-bonusPproducts .slick-slide').removeAttr('role'); }, 10);
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    arrows: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false
                }
            }
        ]
    });

    $('.header-promotion').slick({
        autoplay: true,
        infinite: true,
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        touchMove: true,
        touchThreshold: 500
    });

    $('body').on('einsteinCarousel:setup', function (e) {
        var $tileCarouselEinstein = $('.js-einsteinRecommendation').not('.slick-initialized');
        var hasProduct = false;
        if ($tileCarouselEinstein.length) {
            $tileCarouselEinstein.find('.rec-prod').each(function() {
                if ($(this).find('.product-tile').length > 0) {
                    hasProduct = true;
                }
            });
            if (hasProduct) {
                $tileCarouselEinstein.find('.rec-prod').each(function () {
                    var pid = $(this).find('.product').data('pid');
                    if (pid == null) {
                        $(this).remove();
                    }
                });
                $tileCarouselEinstein.parents('.js-einsteinWrapper').find('.js-sectionHeading').removeClass('d-none');
                $tileCarouselEinstein.each(function (ind, carousel) {
                    var $carousel = $(carousel);
                    $carousel.slick({
                        dots: true,
                        arrows: true,
                        infinite: true,
                        slidesToShow: 4,
                        variableWidth: false,
                        slidesToScroll: 1,
                        customPaging: function () {
                            setTimeout(() => { $carousel.find('.slick-slide').removeAttr('role'); }, 10);
                        },
                        responsive: [
                            {
                                breakpoint: 1200,
                                settings: {
                                    slidesToShow: 4,
                                    slidesToScroll: 1
                                }
                            },
                            {
                                breakpoint: 768,
                                settings: {
                                    slidesToShow: 1,
                                    slidesToScroll: 1,
                                    arrows: false
                                }
                            }
                        ]
                    });
                });
            }
        }
    });

    $(window).on('load', function () {
        $('body').trigger('einsteinCarousel:setup');
    });
};
