'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');

module.exports = function () {
    // Toggle Country Selector Menu
    $('.dropdown.country-selector').on('click', function (e) {
        $(this).toggleClass('active').find('.dropdown-country-selector').toggleClass('active');
    });

    $('.country-dropdown-item').on('click', function (e) {
        e.preventDefault();
        var selectedCountry = $('.country-dropdown-item').attr('data-countrycode');
        var countriesURLset = $('.country-dropdown-item').attr('data-url');
        var countriesJSON = JSON.parse(countriesURLset);
        var redirectURL = '#';
        if (countriesJSON[selectedCountry]) {
            var countryJSON = countriesJSON[selectedCountry];
            redirectURL = countryJSON.url;
        }
        window.location.href = redirectURL;
    });

    keyboardAccessibility('.navbar-header .country-selector',
        {
            40: function ($countryOptions) { // down
                if ($(this).is(':focus')) {
                    $countryOptions.first().focus();
                } else {
                    $(':focus').next().focus();
                }
            },
            38: function ($countryOptions) { // up
                if ($countryOptions.first().is(':focus') || $(this).is(':focus')) {
                    $(this).focus();
                    $(this).removeClass('show active');
                } else {
                    $(':focus').prev().focus();
                }
            },
            27: function () { // escape
                $(this).focus();
                $(this).removeClass('show active').children('.dropdown-menu').removeClass('show active');
            },
            9: function () { // tab
                $(this).removeClass('show active').children('.dropdown-menu').removeClass('show active');
            }
        },
        function () {
            if (!($(this).hasClass('show'))) {
                $(this).addClass('show');
            }
            return $(this).find('.dropdown-country-selector').children('a');
        });

    $('.navbar-header .country-selector').on('focusin', function () {
        $(this).addClass('show').children('.dropdown-menu').addClass('show');
    });
};
